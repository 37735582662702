<template>
  <v-row>
    <v-col  cols="12">
      <base-card>
        <v-card-text v-if="b_QuestionBlocked">
          <base-hover-button
            class="my-2"
            color="primary lighten-5"
            text-color="primary--text"
            :text="$t('general.back')"
            @click.native="goBack()"
            iconName=" mdi-arrow-left-circle"
          />
          <br />
          {{ $t('general.the_question_is_blocked') }}
          <br>
          <base-hover-button
            v-if="IsQSK"
            class="my-2"
            color="success lighten-5"
            text-color="success--text"
            :text="$t('general.release_for_editing')"
            iconName=" mdi-lock-remove-outline"
            @click.native="unblockQuestion(true)"
          />
        </v-card-text>
        <v-card-text v-else>
          <base-hover-button
            class="my-2"
            color="primary lighten-5"
            text-color="primary--text"
            :text="$t('general.back')"
            @click.native="goBack()"
            iconName=" mdi-arrow-left-circle"
            :loading="Lb_Saving"
            :disabled="Lb_Saving"
          />
          <v-divider dark></v-divider>
          <v-progress-linear
            :active="b_Loading"
            indeterminate
            color="primary"
          />
          <div v-if="!b_Loading">
            <!--Status-->
            <v-card outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-select
                  class="pt-3"
                  dense
                  item-text="s_Name"
                  item-value="i_ID"
                  :items="La_Status"
                  v-model="Lo_Question.i_Status"
                  :label="$t('general.status')"
                  @change="$v.Lo_Question.i_Status.$touch()"
                  @blur="$v.Lo_Question.i_Status.$touch()"
                  :error-messages="StatusErrors"
                ></v-select>
                <v-tooltip bottom>
                </v-tooltip>
              </div>
            </v-card>
            <!--End--Status-->
            <!--Author-->
            <v-card outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-select
                item-text="s_Name"
                item-value="AddressID"
                v-model="Lo_Question.a_selectedAuthors"
                :items="Authors"
                :label="$t('general.author')"
                multiple
                @change="$v.Lo_Question.a_selectedAuthors.$touch()"
                @blur="$v.Lo_Question.a_selectedAuthors.$touch()"
                :error-messages="AuthorsErrors"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="Lo_Question.a_selectedAuthors.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{$t('general.select_all')}}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </div>
              <div class=""></div>
            </v-card>
            <!--End--Author-->
            <!--Released-->
            <v-card v-if="IsQSK" outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-select
                  class="pt-3"
                  dense
                  item-text="s_Name"
                  item-value="i_ID"
                  :items="$t('general.a_Released')"
                  v-model="Lo_Question.i_Released"
                  :label="$t('general.release')"
                  @change="$v.Lo_Question.i_Released.$touch()"
                  @blur="$v.Lo_Question.i_Released.$touch()"
                  :error-messages="ReleasedErrors"
                ></v-select>
                <v-tooltip bottom>
                </v-tooltip>
              </div>
              <div class=""></div>
            </v-card>
            <!--End--i_Released-->
            <!--Number-->
            <v-card outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-text-field
                  readonly
                  v-model="Ps_Number"
                  :label="$t('general.number')"
                  required
                  type="number"
                  @input="$v.Lo_Question.s_Number.$touch()"
                  @blur="$v.Lo_Question.s_Number.$touch()"
                  :error-messages="NumberErrors"
                />
                <v-tooltip bottom>
                </v-tooltip>
              </div>
              <div class=""></div>
            </v-card>
            <!--End--Number-->
            <!--Vertiefungsmodul-->
            <v-card outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-select
                  class="pt-3"
                  dense
                  :items="$t('general.a_AdvancedModule')"
                  item-text="s_Name"
                  item-value="s_Shortname"
                  v-model="Lo_Question.s_AdvancedModule"
                  :label="$t('general.advanced_module')"
                  @change="$v.Lo_Question.s_AdvancedModule.$touch()"
                  @blur="$v.Lo_Question.s_AdvancedModule.$touch()"
                  :error-messages="AdvancedModuleErrors"
                ></v-select>
                <v-tooltip bottom>
                </v-tooltip>
              </div>
            </v-card>
            <!--End-Vertiefungsmodul-->
            <!--Wahlmodul-->
            <v-card outlined class="d-flex pa-1 my-1">
              <div class="d-flex flex-row" style="min-width: 25%">
                <v-select
                  class="pt-3"
                  dense
                  :items="$t('general.a_ElectiveModule')"
                  item-text="s_Name"
                  item-value="s_Shortname"
                  v-model="Lo_Question.s_ElectiveModule"
                  :label="$t('general.elective_module')"
                  @change="$v.Lo_Question.s_ElectiveModule.$touch()"
                  @blur="$v.Lo_Question.s_ElectiveModule.$touch()"
                  :error-messages="ElectiveModuleErrors"
                ></v-select>
                <v-tooltip bottom>
                </v-tooltip>
              </div>
            </v-card>
            <!--End-Wahlmodul-->
            <div class="d-flex">
              <v-card
                outlined
                class="d-flex pa-1 my-1 flex-grow-1"
              >
                <div class="d-flex flex-row">
                  <v-text-field
                    v-model="Lo_Question.s_OldNumber"
                    :label="$t('Old question number')"
                    type="number"
                  />
                </div>
              </v-card>
              <!--Tags-->
              <v-card
                outlined
                class="d-flex pa-1 my-1 flex-grow-1 ml-2"
              >
                <div class="d-flex flex-row align-center">
                  <v-select
                    class="pt-3"
                    dense
                    item-value="i_TagID"
                    item-text="s_TagText"
                    :items="Lo_Question.a_Tags"
                    v-model="Lo_Question.s_Tag"
                    :label="$t('general.tag')"
                    clearable
                  ></v-select>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("info.tag") }}</span>
                  </v-tooltip>
                  <v-btn v-if="IsQSK" icon class="ml-2" @click="editTag()">
                    <v-icon color="success">mdi-pencil-box-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="IsQSK" icon @click="dialogNewTag=true">
                    <v-icon color="primary">mdi-plus-box-outline</v-icon>
                  </v-btn>
                </div>
              </v-card>
              <!--End-Tags-->
            </div>
            

          <!--Designation-->
          <v-card outlined class="px-1 my-1">
            <v-row >
              <v-col class="py-0" cols="12" xs="4" sm="7" md="8" lg="6" xl="4">
                <v-text-field
                  :counter="50"
                  v-model="DesignationLength"
                  :label="$t('general.short_description')"
                  required
                  clearable
                  @input="$v.Lo_Question.s_Designation.$touch()"
                  @blur="$v.Lo_Question.s_Designation.$touch()"
                  :error-messages="DesignationErrors"
                />
              </v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("info.designation") }}</span>
              </v-tooltip>
            </v-row>
          </v-card>
          <!--End--Designation-->
          <!--Beschreibung-->
          <div>
            <v-card
              outlined
              class="pa-1 my-1"
              :color="DescriptionErrors ? 'red--text' : ''"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.description") }}:
                  </h6>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("info.description") }}</span>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editDescription">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editDescription = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editDescription">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editDescription = false),
                          $v.Lo_Question.s_Description.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div v-if="!editDescription" v-html="Lo_Question.s_Description" />
              <div
                v-if="DescriptionErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>
              <Tiptap
                v-if="editDescription"
                v-model="Lo_Question.s_Description"
              />
            </v-card>
          </div>
          <!--End--Beschreibung-->
          <!--Rolle der Kandidatin-->
          <div v-if="Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID == 1">
            <v-card
              outlined
              class="pa-1 my-1"
              :color="CandidateRoleErrors ? 'red--text' : ''"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.role_of_the_candidate") }}:
                  </h6>
                  <v-tooltip bottom>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editRolle = true),
                          $v.Lo_Question.s_CandidateRole.$touch()
                      "
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="successs"
                      @click="
                        (editRolle = false),
                          $v.Lo_Question.s_CandidateRole.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div v-if="!editRolle" v-html="Lo_Question.s_CandidateRole" />
              <div
                v-if="CandidateRoleErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>
              <Tiptap v-if="editRolle" v-model="Lo_Question.s_CandidateRole" />
            </v-card>
          </div>
          <!--End--Rolle der Kandidatin-->
          <!--InitialSituation-->
          <div>
            <v-card
              outlined
              class="pa-1 my-1"
              :color="InitialSituationErrors ? 'red--text' : ''"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.initial_situation") }}:
                  </h6>
                  <v-tooltip bottom>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editAusgang = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editAusgang = false),
                          $v.Lo_Question.s_InitialSituation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editAusgang"
                v-html="Lo_Question.s_InitialSituation"
              />
              <div
                v-if="InitialSituationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>
              <Tiptap
                v-if="editAusgang"
                v-model="Lo_Question.s_InitialSituation"
              />
            </v-card>
          </div>
          <!--End--InitialSituation-->
          <!--Test Presentation-->
          <div v-if="Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID == 2">
            <v-card
              outlined
              :color="TestPresentationErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.task_definition") }}:
                  </h6>
                  <v-tooltip bottom>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editPresentation = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editPresentation = false),
                          $v.Lo_Question.s_TestPresentation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editPresentation"
                v-html="Lo_Question.s_TestPresentation"
              />

              <div
                v-if="TestPresentationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editPresentation"
                v-model="Lo_Question.s_TestPresentation"
              />
            </v-card>
          </div>
          <!--End Test presentation-->
          <!--Solution Presentation-->
          <div v-if="Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID == 2">
            <v-card
              outlined
              :color="SolutionPresentationErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.solution_presentation") }}:
                  </h6>
                  <v-tooltip bottom>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editSolutionPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editSolutionPresentation = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editSolutionPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editSolutionPresentation = false),
                          $v.Lo_Question.s_SolutionPresentation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editSolutionPresentation"
                v-html="Lo_Question.s_SolutionPresentation"
              />

              <div
                v-if="SolutionPresentationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editSolutionPresentation"
                v-model="Lo_Question.s_SolutionPresentation"
              />
            </v-card>
          </div>
          <!--End SolutionPresentation -->
          <v-divider class="my-4" />
          <div class="my-4" />
          <!--Frage an den Kandidaten/an die Kandidatin:-->
          <div>
            <div class="d-flex flex-row">
              <h6 class="font-weight-bold">
                {{ $t("general.questions_for_the_candidate") }}:
              </h6>
              <v-tooltip bottom>
              </v-tooltip>
            </div>
            <v-alert dense outlined type="error" v-if="QuestionsSizeErrors">
              {{ MinNumberofMandatoryQuestions }} - {{ MaxNumberofMandatoryQuestions }}
              {{ $t("errormessages.questions_must_be_present_as_a_minimum") }}
            </v-alert>
            <v-card
              outlined
              class="pa-1 my-1"
              v-for="(o_Question, index) in Lo_Question.a_Questions"
              :key="index"
              :color="QuestionErrors(index) ? 'red--text' : ''"
            >
              <div class="d-flex justify-space-between">
                <h6>{{ $t("general.question") }} {{ index + 1 }} :</h6>
                <div>
                  <v-tooltip bottom v-if="index > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="primary"
                        @click="moveQuestion(o_Question, index, 'up')"
                      >
                        <v-icon>mdi-chevron-up-box</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Up") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="index < Lo_Question.a_Questions.length - 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="primary"
                        @click="moveQuestion(o_Question, index, 'down')"
                      >
                        <v-icon>mdi-chevron-down-box</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Down") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = true"
                      >
                        <v-icon>mdi-pencil-box-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = false"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.close") }}</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID != 1
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="danger"
                        @click="dialog = true, SetQuestionNrToBeDelete(o_Question.i_SSN_QuestionID)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.delete") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div>
                <div
                  v-if="
                    !editFragen ||
                    (Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID == 1)
                  "
                  class="mb-3"
                  v-html="o_Question.s_Question"
                />
                <div
                  v-if="QuestionErrors(index)"
                  class="v-messages theme--light error--text"
                  role="alert"
                >
                  {{ $t("errormessages.is_required") }}
                </div>
                <Tiptap
                  v-if="
                    editFragen &&
                    Lo_Question.i_TestTypeID &&
                    Lo_Question.i_TestTypeID != 1
                  "
                  v-model="o_Question.s_Question"
                />
                <h6 v-if="!editFragen">
                  {{ $t("general.answer") }} {{ index + 1 }} :
                </h6>
                <div
                  v-if="!editFragen"
                  class=""
                  v-html="o_Question.s_ExpectedAnswer"
                />
                <h6 v-if="editFragen">
                  {{ $t("general.answer") }} {{ index + 1 }} :
                </h6>
                <div
                  v-if="QuestionErrors(index)"
                  class="v-messages theme--light error--text"
                  role="alert"
                >
                  {{ $t("errormessages.is_required") }}
                </div>
                <Tiptap
                  v-if="editFragen"
                  v-model="o_Question.s_ExpectedAnswer"
                />
              </div>
            </v-card>
            <v-dialog v-model="dialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("general.you_will_delete_a_question") }}!
                  </v-card-title>
                  <v-card-text class="black--text"
                    >{{
                      $t("general.would_you_like_to_continue")
                    }}?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="Lb_Deleting"
                      :disabled="Lb_Deleting"
                      color="green darken-1"
                      text
                      @click="dialog = false, i_QuestionNrToBeDelete = 0"
                    >
                      {{ $t("general.no") }}
                    </v-btn>
                    <v-btn
                      :loading="Lb_Deleting"
                      :disabled="Lb_Deleting"
                      color="red darken-1"
                      text
                      @click="deleteSubQuestion(i_QuestionNrToBeDelete)"
                    >
                      {{ $t("general.delete") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            <!--End--Frage an den Kandidaten/an die Kandidatin:-->
            <base-hover-button
              v-if="Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID != 1"
              class="my-2"
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.new_question')"
              @click.native="addItem()"
              iconName=" mdi-plus"
            />
            <v-divider></v-divider>
            <div class="d-flex justify-space-between mt-5">
              <base-hover-button
                color="success lighten-5"
                :text="$t('general.save')"
                textColor="success--text"
                @click.native="saveQuestion()"
                iconName="mdi-content-save"
                :loading="Lb_Saving"
              />

              <base-hover-button
                color="primary lighten-5"
                text-color="primary--text"
                :text="$t('general.back')"
                @click.native="goBack()"
                iconName=" mdi-arrow-left-circle"
                :loading="Lb_Saving"
                :disabled="Lb_Saving"
              />
            </div>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="Lb_Saving"
                :disabled="Lb_Saving"
                color="success"
                class="saveButton white--text"
                fab
                small
                @click.native="saveQuestion()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("general.save") }}</span>
          </v-tooltip>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog v-model="Lb_ErrorDialog" persistent max-width="290">
      <v-card>
        <v-app-bar dark color="danger"
          ><v-card-title color="primary" class="text-h5">
            Opps
          </v-card-title></v-app-bar
        >

        <v-card-text class="mt-4 text-h5 black--text">{{
          $t("errormessages.the_form_must_be_complete")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="Lb_ErrorDialog = false">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogExitWithoutSave" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("You will leave the question without save the changes") }}!
        </v-card-title>
        <v-card-text class="black--text"
          >{{
            $t("general.would_you_like_to_continue")
          }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="Lb_Deleting"
            :disabled="Lb_Deleting"
            color="red darken-1"
            text
            @click.native="confirmDialog()"
          >
            {{ $t("yes") }}
          </v-btn>
          <v-btn
            :loading="Lb_Deleting"
            :disabled="Lb_Deleting"
            color="primary"
            text
            @click.native="cancelDialog()"
          >
            {{ $t("general.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewTag" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("New tag") }}
        </v-card-title>
        <v-card-text class="black--text">
          <v-text-field
            v-model="o_CurrentTag.s_TagDE"
            label="Stichwort"
            outlined
            dense
            :error-messages="NewTagErrors.de"
          ></v-text-field>
          <v-text-field
            v-model="o_CurrentTag.s_TagFR"
            label="Mot-clé"
            outlined
            dense
            :error-messages="NewTagErrors.fr"
          ></v-text-field>
          <v-text-field
            v-model="o_CurrentTag.s_TagIT"
            label="Parola chiave"
            outlined
            dense
            :error-messages="NewTagErrors.it"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="cancelTagEdition()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click="setTag()"
            :loading="Lb_Saving"
            :disabled="Lb_Saving"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Tiptap from "../../../components/editor/Tiptap.vue";
import { post, get } from "../../../worker/worker-api";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
const notOnly = (value) => value.indexOf("<p></p>") < 0;
export default {
  mixins: [validationMixin],
  name: "EditQuestion",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.edit_question"),
    };
  },
  components: {
    Tiptap,
  },
  props: {
    i_TestID: {
      type: Number,
    },
    i_LangID: {
      type: Number,
    },
    i_Translation: {
      type: Number,
      default: 0,
    },
    Pi_TestTypeID: {
      type: Number,
      default: 0,
    },
    Ps_Number: {
      type: Number,
    }
  },
  data() {
    return {
      i_QuestionNrToBeDelete:0,
      b_Loading: true,
      b_QuestionBlocked: false,
      dialog: false,
      dialogExitWithoutSave: false,
      dialogEditTag: false,
      dialogNewTag: false,
      editDesignation: false,
      editDescription: false,
      editRolle: false,
      editAusgang: false,
      editPresentation: false,
      editSolutionPresentation: false,
      editFragen: false,
      Lb_Saving: false,
      Lb_Deleting: false,
      o_newQuestion: {},
      Lo_Question: {
        s_MainLang: null,
        i_TestID: null,
        i_TestTypeID: null, //FG=2 CI=1
        s_Autor: "",
        i_Status: "",
        i_Released: null,
        s_Number: "",
        s_Designation: "",
        s_Description: "",
        s_CandidateRole: "",
        s_InitialSituation: "",
        s_TestPresentation: "",
        s_SolutionPresentation: "",
        s_AdvancedModule: "",
        s_ElectiveModule: "",
        s_Tag: "",
        a_Tags: [],
        a_Questions: [],
        a_selectedAuthors: [],
        s_AuthorsStringList: "",
        i_AuthorAddressRoleID: null
      },
      Lb_ErrorDialog: false,
      Authors: [],
      La_Status: [],
      La_CISubQuestions: null,
      La_DeCISubQuestions: [
        "Beschreiben Sie die Gefährdungen.",
        "Welches sind die rechtlichen Grundlagen?",
        "Welche Massnahmen treffen Sie?",
        "Begründen Sie Ihre Massnahmen.",
      ],
      La_FrCISubQuestions: [
        "Décrivez les phénomènes dangereux.",
        "Quelles sont les bases juridiques?",
        "Quelles mesures prenez-vous?",
        "Justifiez vos mesures.",
      ],
      La_ItCISubQuestions: [
        "Descriva i pericoli.",
        "Quali sono le basi legali?",
        "Quali misure adotta?",
        "Giustifichi la scelta delle sue misure.",
      ],
      resolve: null,
      reject: null,
      b_ChangesWithoutSave: false,
      o_CurrentTag: {
        i_TagID: null,
        s_TagDE: null,
        s_TagFR: null,
        s_TagIT: null,
      }
    };
  },
  validations() {
    let Lo_Validations
    if (this.Pi_TestTypeID == 1) {
      Lo_Validations = {
        Lo_Question: {
          i_Status: { required },
          a_selectedAuthors: { required, minLength: minLength(1) },
          i_Released: { required },
          s_Number: { required },
          s_Designation: { required, maxLength: maxLength(50) },
          s_Description: { required /*notOnly*/ },
          s_CandidateRole: { required /*notOnly*/ },
          s_InitialSituation: { required /*notOnly*/ },
          s_AdvancedModule: { required },
          s_ElectiveModule: { required },
          //s_Tag: { required },
          a_Questions: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(4),
            $each: {
              s_Question: {
                required,
                /*notOnly*/
              },
              s_ExpectedAnswer: {
                required,
                /*notOnly*/
              },
            },
          },
        },
      };
    } else if (this.Pi_TestTypeID == 2) {
      Lo_Validations = {
        Lo_Question: {
          i_Status: { required },
          a_selectedAuthors: { required, minLength: minLength(1) },
          i_Released: { required },
          s_Number: { required },
          s_Designation: { required, maxLength: maxLength(50) },
          s_Description: { required /*notOnly*/ },
          s_InitialSituation: { required /*notOnly*/ },
          s_TestPresentation: { required /*notOnly*/ },
          s_SolutionPresentation: { required /*notOnly*/ },
          s_AdvancedModule: { required },
          s_ElectiveModule: { required },
          //s_Tag: { required },
          a_Questions: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(16),
            $each: {
              s_Question: {
                required,
                /*notOnly*/
              },
              s_ExpectedAnswer: {
                required,
                /*notOnly*/
              },
            },
          },
        },
      };
    }
    Lo_Validations['o_CurrentTag'] = {
      s_TagDE: { required: requiredIf(() => this.dialogNewTag)},
      s_TagFR: { required: requiredIf(() => this.dialogNewTag)},
      s_TagIT: { required: requiredIf(() => this.dialogNewTag)},
    }
    return Lo_Validations
  },
  computed: {
    ...mapGetters(['getProfile', 'getRoles', 'getUserID', 'getCurrentRole']),
    computedQuestion () {
      return Object.assign({}, this.Lo_Question)
    },
    MinNumberofMandatoryQuestions() {
      if (this.Lo_Question.i_TestTypeID && this.Lo_Question.i_TestTypeID == 1) {
        return 4;
      }
      return 8;
    },
    MaxNumberofMandatoryQuestions() {
      if (this.Lo_Question.i_TestTypeID && this.Lo_Question.i_TestTypeID == 1) {
        return 4;
      }
      return 16;
    },
    IsQSK() {
      let result = this.getRoles.filter((Roles) => Roles.s_Role == "qsk");
      if (result.length > 0) {
        return true;
      }
      return false;
    },
    NumberErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_Number.$dirty) return errors;
      !this.$v.Lo_Question.s_Number.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    StatusErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.i_Status.$dirty) return errors;
      !this.$v.Lo_Question.i_Status.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    AuthorsErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.a_selectedAuthors.$dirty) return errors;
      !this.$v.Lo_Question.a_selectedAuthors.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    ReleasedErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.i_Released.$dirty) return errors;
      !this.$v.Lo_Question.i_Released.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    DesignationErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_Designation.$dirty) return errors;
      !this.$v.Lo_Question.s_Designation.required &&
        errors.push(this.$t("errormessages.is_required"));
      !this.$v.Lo_Question.s_Designation.maxLength &&
        errors.push(this.$t("errormessages.50_characters_maximum"));
      return errors;
    },
    AdvancedModuleErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_AdvancedModule.$dirty) return errors;
      !this.$v.Lo_Question.s_AdvancedModule.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    ElectiveModuleErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_ElectiveModule.$dirty) return errors;
      !this.$v.Lo_Question.s_ElectiveModule.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    DescriptionErrors() {
      if (!this.$v.Lo_Question.s_Description.$dirty) return false;
      if (!this.$v.Lo_Question.s_Description.required) return true;
      //if (!this.$v.Lo_Question.s_Description.notOnly) return true;
    },
    CandidateRoleErrors() {
      if (!this.$v.Lo_Question.s_CandidateRole.$dirty) return false;
      if (!this.$v.Lo_Question.s_CandidateRole.required) return true;
      //if (!this.$v.Lo_Question.s_CandidateRole.notOnly) return true;
    },
    InitialSituationErrors() {
      if (!this.$v.Lo_Question.s_InitialSituation.$dirty) return false;
      if (!this.$v.Lo_Question.s_InitialSituation.required) return true;
      //if (!this.$v.Lo_Question.s_InitialSituation.notOnly) return true;
    },
    TestPresentationErrors() {
      if (!this.$v.Lo_Question.s_TestPresentation.$dirty) return false;
      if (!this.$v.Lo_Question.s_TestPresentation.required) return true;
      //if (!this.$v.Lo_Question.s_TestPresentation.notOnly) return true;
    },
    SolutionPresentationErrors() {
      if (!this.$v.Lo_Question.s_SolutionPresentation.$dirty) return false;
      if (!this.$v.Lo_Question.s_SolutionPresentation.required) return true;
      //if (!this.$v.Lo_Question.s_SolutionPresentation.notOnly) return true;
    },
    QuestionsSizeErrors() {
      if (!this.$v.Lo_Question.a_Questions.$dirty) return false;
      if (!this.$v.Lo_Question.a_Questions.required) return true;
      if (!this.$v.Lo_Question.a_Questions.minLength) return true;
    },
    NewTagErrors() {
      const errors = {
        de: [],
        fr: [],
        it: [],
      }
      if (!this.$v.o_CurrentTag.$dirty) return errors;
      !this.$v.o_CurrentTag.s_TagDE.required &&
        errors.de.push(this.$t("errormessages.is_required"));
      !this.$v.o_CurrentTag.s_TagFR.required &&
        errors.fr.push(this.$t("errormessages.is_required"));
      !this.$v.o_CurrentTag.s_TagIT.required &&
        errors.it.push(this.$t("errormessages.is_required"));
      return errors;
    },
    likesAllAuthor() {
      return this.Lo_Question.a_selectedAuthors.length === this.Authors.length;
    },
    likesSomeAuthor() {
      return (
        this.Lo_Question.a_selectedAuthors.length > 0 && !this.likesAllAuthor
      );
    },
    icon() {
      if (this.likesAllAuthor) return "mdi-close-box";
      if (this.likesSomeAuthor) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    DesignationLength: {
      get() {
        if (
          this.Lo_Question.s_Designation != null &&
          this.Lo_Question.s_Designation.length > 51
        ) {
          let s_DesignationText = this.Lo_Question.s_Designation;
          let result = s_DesignationText.substr(0, 50);

          return result;
        } else {
          return this.Lo_Question.s_Designation;
        }
      },
      set(val) {
        this.Lo_Question.s_Designation = val;
      },
    },
  },
  methods: {
    QuestionErrors(Ai_Index) {
      if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.$dirty ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer.$dirty
      )
        return false;
      if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.required ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer
          .required
      )
        return true;
      /*if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.notOnly ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer
          .notOnly
      )
        return true;*/
    },
    addItem: function () {
      this.Lo_Question.a_Questions.push({ ...this.o_newQuestion });
    },
    SetQuestionNrToBeDelete: function (Ai_SSN_QuestionID) {
      this.i_QuestionNrToBeDelete = Ai_SSN_QuestionID;
    },
    deleteSubQuestion(Ai_i_SSN_QuestionID) {
      if(Ai_i_SSN_QuestionID > 0){
        this.Lb_Deleting = true;
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/delete-Sub-question`, {
          i_SSN_QuestionID: Ai_i_SSN_QuestionID,
        })
          .then((response) => {
            this.getQuestion();
            this.Lb_Deleting = false;
            this.dialog = false;
          })
          .catch((errors) => {
            console.log("submit Worker error: ", errors);
            this.Lb_Deleting = false;
            this.dialog = false;
          });
      }
    },
    getQuestion() {
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/question`, {
        i_TestID: this.i_TestID,
        i_LangID: this.i_LangID,
        i_Translation: this.i_Translation,
        i_PersonID: this.getUserID,
      })
        .then((response) => {
          if (response.status === 401) {
            this.signOut();
            this.$smt.logout();
            return;
          }
          this.Authors = this.sortAuthors(response.a_AuthorsList);
          this.Lo_Question.a_selectedAuthors = JSON.parse(
            "[" + response.a_Authors.slice(1, -1) + "]"
          );
          this.Lo_Question.s_MainLang = response.s_MainLang;
          this.Lo_Question.i_TestID = response.i_TestID;
          this.Lo_Question.i_TestTypeID = response.i_TestTypeID;
          this.Lo_Question.s_Autor = response.s_Autor;
          this.Lo_Question.i_Status = response.i_Status;
          this.Lo_Question.i_Released = response.i_Released;
          this.Lo_Question.s_Number = response.s_Number;
          this.Lo_Question.s_Designation = response.s_Designation;
          this.Lo_Question.s_Description = response.s_Description;
          this.Lo_Question.s_CandidateRole = response.s_CandidateRole;
          this.Lo_Question.s_InitialSituation = response.s_InitialSituation;
          this.Lo_Question.s_TestPresentation = response.s_TestPresentation;
          this.Lo_Question.s_SolutionPresentation = response.s_SolutionPresentation;
          this.Lo_Question.s_AdvancedModule = response.s_AdvancedModule;
          this.Lo_Question.s_ElectiveModule = response.s_ElectiveModule;
          this.Lo_Question.s_Tag = response.s_Tag;
          this.Lo_Question.a_TagsTranslated = response.a_TagsTranslated;
          this.Lo_Question.a_Tags = response.a_Tags.sort((a, b) => a.s_TagText.toUpperCase() < b.s_TagText.toUpperCase() ? -1 : 1);
          this.Lo_Question.a_Questions = response.a_Questions.sort((a, b) => a.i_Order < b.i_Order ? -1 : 1)
          this.Lo_Question.s_OldNumber = response.s_OldNumber;
          if (this.Pi_TestTypeID == 1) {
            this.SetCISubQuestions(this.i_LangID);
          }
          if (response.i_InEditingPersonID !== 0 
            && response.i_InEditingPersonID !== null 
            && parseInt(this.getUserID) !== response.i_InEditingPersonID) {
              this.b_QuestionBlocked = true
            }
          this.b_Loading = false
          this.b_ChangesWithoutSave = false
        })
        .catch((error) => {
          this.b_Loading = false
          console.log("Worker error: ", error);
        });
    },
    saveQuestion() {
      if (this.Lo_Question.i_Status == 0 || this.Lo_Question.i_Status == 5) {
        this.Lb_Saving = true;
        this.Lo_Question.i_AuthorAddressRoleID = this.getCurrentRole.i_AddressRoleID
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/set-edited-question`, {
          o_Question: this.Lo_Question
        })
          .then((response) => {
            this.Lb_Saving = false
            this.Lo_Question = response.o_Question
            this.b_ChangesWithoutSave = false
          })
          .catch((errors) => {
            console.log("submit Worker error: ", errors);
          });
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.Lb_ErrorDialog = true;
        }
        if (!this.$v.$invalid) {
          this.Lb_Saving = true;
          post(
            `${process.env.VUE_APP_SMT_API_URL}/teacher/set-edited-question`,
            {
              o_Question: this.Lo_Question,
            }
          )
            .then((response) => {
              this.Lb_Saving = false;
              this.Lo_Question = response.o_Question
            })
            .catch((errors) => {
              console.log("submit Worker error: ", errors);
            });
        }
      }
    },
    unblockQuestion(Ai_QSK) {
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/unblock-question`, {
        i_TestID: this.Lo_Question.i_TestID,
      })
        .then((response) => {
          if(Ai_QSK){
            this.goBack();
          }
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
        });
    },
    async goBack() {
      if (this.b_ChangesWithoutSave) {
        if (await this.openDialog()) {
          if (this.Lo_Question.i_TestTypeID == 1) {
            this.$router.push({ name: "ci-oral-questions" });
          } else if (this.Lo_Question.i_TestTypeID == 2) {
            this.$router.push({ name: "fg-oral-questions" });
          } else {
            this.$router.back();
          }
          return
        }
        else return
      }
      if (this.Lo_Question.i_TestTypeID == 1) {
        this.$router.push({ name: "ci-oral-questions" });
      } else if (this.Lo_Question.i_TestTypeID == 2) {
        this.$router.push({ name: "fg-oral-questions" });
      } else {
        this.$router.back();
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllAuthor) {
          this.Lo_Question.a_selectedAuthors = [];
        } else {
          this.Lo_Question.a_selectedAuthors = this.Authors.slice();
        }
      });
    },
    sortAuthors(A_Authors) {
      const sortedlist = A_Authors.sort(function (a, b) {
        const nameA = a.s_Name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.s_Name.toUpperCase(); // ignore upper and lowercase

        // sort in an ascending order
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return sortedlist;
    },
    getRole() {
      let AuthorRole = null;
      let TranslatorRole = null;
      let QSKRole = null;
      let roles = this.getRoles.filter(
        (Roles) =>
          Roles.s_Role == "translator" ||
          Roles.s_Role == "author" ||
          Roles.s_Role == "qsk"
      );
      for (let x in roles) {
        if (roles[x].s_Role == "qsk") {
          QSKRole = true;
        } else if (roles[x].s_Role == "translator") {
          TranslatorRole = true;
        } else if (roles[x].s_Role == "author") {
          AuthorRole = true;
        }
      }
      if (QSKRole) {
        return (this.La_Status = this.$t("general.a_Status"));
      }
      if (AuthorRole && TranslatorRole && !QSKRole) {
        return (this.La_Status = this.$t("general.a_Status").filter(
          (status) => status.i_ID == 2 || status.i_ID == 1
        ));
      } else if (AuthorRole && !QSKRole) {
        return (this.La_Status = this.$t("general.a_Status").filter(
          (status) => status.i_ID == 0 || status.i_ID == 1
        ));
      }
    },
    SetCISubQuestions (Ai_LangID) {
      if (Ai_LangID == 1) {
        this.La_CISubQuestions = this.La_DeCISubQuestions;
      } else if (Ai_LangID == 2) {
        this.La_CISubQuestions = this.La_FrCISubQuestions;
      } else if (Ai_LangID == 3) {
        this.La_CISubQuestions = this.La_ItCISubQuestions;
      }
      this.Lo_Question.a_Questions.forEach((el, index) => {
        this.Lo_Question.a_Questions[index].s_Question =
          this.La_CISubQuestions[index];
      });
    },
    updateClassSizeImage () {
      let La_ImageSize = ['size-image-s', 'size-image-m', 'size-image-l']
      La_ImageSize.forEach(imgSize => {
        let imgElement = document.querySelectorAll(`img[alt="${imgSize}"]`)
        const La_Images = Array.from(imgElement);
        La_Images.forEach(image => {
          image.className = ''
          image.classList.add(imgSize)
        })
      })
    },
    openDialog () {
      this.dialogExitWithoutSave = true
      return new Promise ((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirmDialog () {
      this.resolve(true)
      this.dialogExitWithoutSave = false
    },
    cancelDialog () {
      this.resolve(false)
      this.dialogExitWithoutSave = false
    },
    setTag () {
      this.$v.o_CurrentTag.$touch();
      if (this.$v.o_CurrentTag.$invalid) return
      this.Lb_Saving = true
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/set-tag`, {
          i_AddressRoleID: 0,
          o_Tag: this.o_CurrentTag
        })
          .then(response => {
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            let Ls_TagText
            if (this.i_LangID === 1) Ls_TagText = this.o_CurrentTag.s_TagDE
            if (this.i_LangID === 2) Ls_TagText = this.o_CurrentTag.s_TagFR
            if (this.i_LangID === 3) Ls_TagText = this.o_CurrentTag.s_TagIT
            if (this.o_CurrentTag.i_TagID === null) {
              this.Lo_Question.a_Tags.push({
                i_DatasetItemID: response.i_DatasetItemID,
                i_TagID: response.i_TagID,
                s_TagText: Ls_TagText
              })
            } else {
              const La_CurrentTag = this.Lo_Question.a_Tags.filter(tag => tag.i_TagID === this.Lo_Question.s_Tag)
              La_CurrentTag[0].s_TagText = Ls_TagText
            }
            this.Lo_Question.a_Tags.sort((a, b) => a.s_TagText.toUpperCase() < b.s_TagText.toUpperCase() ? -1 : 1)
            this.Lb_Saving = false
            this.dialogNewTag = false
            this.o_CurrentTag.i_TagID = null
            this.o_CurrentTag.s_TagDE = null
            this.o_CurrentTag.s_TagFR = null
            this.o_CurrentTag.s_TagIT = null
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
    },
    editTag () {
      if (this.Lo_Question.s_Tag === null || this.Lo_Question.s_Tag === '') return
      const La_CurrentTag = this.Lo_Question.a_Tags.filter(tag => tag.i_TagID === this.Lo_Question.s_Tag)
      this.o_CurrentTag.i_TagID = La_CurrentTag[0].i_TagID
      const La_TagTranslated = this.Lo_Question.a_TagsTranslated.filter(tag => tag.i_TagID === this.Lo_Question.s_Tag)
      const La_CurrentTagDE  = La_TagTranslated.filter(tag => tag.i_LangID === 1)
      const La_CurrentTagFR  = La_TagTranslated.filter(tag => tag.i_LangID === 2)
      const La_CurrentTagIT  = La_TagTranslated.filter(tag => tag.i_LangID === 3)
      this.dialogNewTag = true
      this.o_CurrentTag.i_TagID = this.Lo_Question.s_Tag
      this.o_CurrentTag.s_TagDE = La_CurrentTagDE.length > 0 ? La_CurrentTagDE[0].s_TagText : La_CurrentTag[0].s_TagText
      this.o_CurrentTag.s_TagFR = La_CurrentTagFR.length > 0 ? La_CurrentTagFR[0].s_TagText : La_CurrentTag[0].s_TagText
      this.o_CurrentTag.s_TagIT = La_CurrentTagIT.length > 0 ? La_CurrentTagIT[0].s_TagText : La_CurrentTag[0].s_TagText
    },
    cancelTagEdition () {
      this.dialogNewTag = false
      this.o_CurrentTag.i_TagID = null
      this.o_CurrentTag.s_TagDE = null
      this.o_CurrentTag.s_TagFR = null
      this.o_CurrentTag.s_TagIT = null
    },
    moveQuestion(Ao_Question, Ai_Index,  As_Direction) {
      if (As_Direction === 'down')
        this.Lo_Question.a_Questions[Ai_Index] = this.Lo_Question.a_Questions.splice(Ai_Index + 1, 1, this.Lo_Question.a_Questions[Ai_Index])[0]
      if (As_Direction === 'up')
        this.Lo_Question.a_Questions[Ai_Index - 1] = this.Lo_Question.a_Questions.splice(Ai_Index, 1, this.Lo_Question.a_Questions[Ai_Index - 1])[0]
    }
  },
  created() {
    this.getRole();
    this.Lo_Question.i_Language = this.i_LangID;
    if (this.i_TestID && this.i_LangID && this.i_Translation != null) {
      this.getQuestion();
    } else {
      this.$router.back();
    }
  },
  updated() {
    this.updateClassSizeImage()
  },
  beforeDestroy() {
    if (!this.b_QuestionBlocked) this.unblockQuestion(false)
  },
  mounted() {
    this.b_ChangesWithoutSave = false
  },
  watch: {
    computedQuestion: {
      handler (newVal, oldVal) {
        if (oldVal.i_TestID === null) return
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) return
        this.b_ChangesWithoutSave = true
      },
      deep: true
    },
    validations: {
      
    },
    "Lo_Question.a_selectedAuthors": function (val) {
      let La_Author
      if (this.likesAllAuthor) {
        La_Author = val.map(author => author.AddressID)
      } else {
        La_Author = val
      }
      this.Lo_Question.s_AuthorsStringList = La_Author.toString();
    },
  },
};
</script>
<style lang="scss">
* {
    --var-image-width: 50%;
    --var-image-width-s: 33%;
    --var-image-width-m: 66%;
    --var-image-width-l: 100%;
  }

.custom-image-class {
  max-width: var(--var-image-width);
  height: auto;
}

.size-image-s {
  max-width: var(--var-image-width-s) !important;
  height: auto;
}

.size-image-m {
  max-width: var(--var-image-width-m) !important;
  height: auto;
}

.size-image-l {
  max-width: var(--var-image-width-l) !important;
  height: auto;
}
</style>
<style lang="scss" scoped>
::v-deep .v-label {
  color: black;
}
</style>
<style lang="scss" scoped>
::v-deep .v-label {
  color: rgba(0, 0, 0, 0.87);
}
::v-deep .theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: bold;
}
.saveButton {
  position: fixed;
  right: 2px;
  bottom: 15px;
  z-index: 2;
}
.v-tooltip__content {
  max-width: 20%;
}
</style>

